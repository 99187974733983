import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { NotFoundError, defaultSanityClient } from '@konsus/sanity-client';
import { sanity } from '@konsus/utils';

import { ANNOUNCEMENT_BAR_SANITY_QUERY } from '../queries/announcementBar';
import { BLOG_ANNOUNCEMENT_BAR_SANITY_QUERY } from '../queries';
import SanityPage, { getStaticPropsFactory } from '../components/SanityPage';

const getStaticPropsBase = getStaticPropsFactory('homepage-axolotl');

const ignoreNotFoundError = (err) => {
  if (err instanceof NotFoundError) {
    return null;
  }
  throw err;
};

export async function getStaticProps(context) {
  const [staticProps, announcement, bottomAnnouncement] = await Promise.all([
    getStaticPropsBase(context),
    sanity.fetch(ANNOUNCEMENT_BAR_SANITY_QUERY, { preview: false }).catch(ignoreNotFoundError),
    sanity.fetch(BLOG_ANNOUNCEMENT_BAR_SANITY_QUERY, { preview: false })
  ]);

  const openGraphImage = staticProps.props.data.content.openGraphImage;
  const ogImageSrc = openGraphImage
    ? imageUrlBuilder(defaultSanityClient)
        .image(openGraphImage)
        .width(1200)
        .height(630)
        .fit('max')
        .url()
    : null;

  return {
    ...staticProps,
    props: {
      ...staticProps.props,
      data: {
        ...staticProps.props.data,
        ogImageSrc,
        announcement,
        bottomAnnouncement
      },
      helmetProps: {
        canonical: 'https://www.superside.com',
        ogImageSrc
      }
    },
    revalidate: 120
  };
}

const Page = (props) => <SanityPage {...props} hasAnnouncementBar />;

export default Page;
