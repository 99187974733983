import dynamic from 'adapter/next/dynamic';
import LegoGlobalContext, {
  GlobalContextProvider as LegoGlobalContextProvider
} from './contexts/GlobalContext';
import { type BuildingBlocksProps } from './utils/render';
import blockByType from './blocks/blockByType';

const BuildingBlocks: any = dynamic<BuildingBlocksProps>(
  () => import(/* webpackChunkName: "tier-0" */ './utils/render')
);

export { LegoGlobalContext, LegoGlobalContextProvider, blockByType };

export default BuildingBlocks;
