import React, { useEffect } from 'react';
import { useAtomMutator } from '@konsus/atoms';
import { lightToDarkSwitchAtom } from '@konsus/lego/src/utils';

export const ThemeSwitch: React.FC<{ hasLightToDarkTransition: boolean }> = ({
  hasLightToDarkTransition
}) => {
  const setIsThemeToggled = useAtomMutator(lightToDarkSwitchAtom);

  useEffect(() => {
    if (hasLightToDarkTransition) {
      const themeColorToggle = () => {
        const shouldToggleTheme = window.scrollY > 500;

        document.body.dataset.toggleThemeColor = shouldToggleTheme ? 'false' : 'true';
        setIsThemeToggled(!shouldToggleTheme);
      };

      window.addEventListener('scroll', themeColorToggle);
      themeColorToggle();

      return () => {
        window.removeEventListener('scroll', themeColorToggle);
        delete document.body.dataset.toggleThemeColor;
      };
    } else {
      setIsThemeToggled(null);
    }
  }, [hasLightToDarkTransition]);

  return <></>;
};

export default ThemeSwitch;
