import { type GetStaticPropsContext } from 'next/types';
import { BOTTOM_CALL_OUT_BANNER_IDS } from '@konsus/apps-common';
import { PAGE_SANITY_QUERY, ALL_POPUP_RULES } from '../../queries';
import { preparePopup } from '../popups';
import { fetchHeaderNavigation } from '../../queries/navigation/headerNavigationQuery';
import { SKELETON_NAVIGATION_FOOTER_NAVIGATION_QUERY } from '../../queries/navigation/footerNavigationQuery';
import SanityPage from './SanityPage';
import { fetchAndProcessImages, sanity } from './utils';

export function getStaticPropsFactory(key?: string, path?: string) {
  return async (context: GetStaticPropsContext) => {
    return getSanityPageStaticProps(context, key, path);
  };
}

export const getSanityPageStaticProps = async (
  context: GetStaticPropsContext,
  key?: string,
  path?: string,
  dataFormatter?: (staticProps: any) => any,
  query = PAGE_SANITY_QUERY,
  slug?: string[]
) => {
  try {
    const [content, popupsRules, headerNavigation, footer] = await Promise.all([
      key
        ? await sanity
            .fetch(query, {
              key,
              ...(slug && { slug }),
              preview: false,
              bannerId: BOTTOM_CALL_OUT_BANNER_IDS.MAIN
            })
            .then((res) => {
              return res;
            })
            .catch((error) => {
              if (!error) {
                return undefined;
              }

              throw error;
            })
        : null,
      sanity.fetch(ALL_POPUP_RULES),
      fetchHeaderNavigation(),
      sanity.fetch(SKELETON_NAVIGATION_FOOTER_NAVIGATION_QUERY)
    ]);

    if (!content) {
      return {
        notFound: true,
        props: {}
      };
    }

    const popups = preparePopup(popupsRules, path);

    const legoBlocksData =
      content &&
      (await import('../../utils/getLegoBlocksData').then(({ getLegoBlocksData }) =>
        getLegoBlocksData(content, context)
      ));

    const imagesAltText = await fetchAndProcessImages(content);

    const staticProps = {
      props: {
        data: {
          content,
          skeleton: {
            navigation: {
              headerNavigation,
              footerNavigation: footer
            }
          }
        },
        legoBlocksData,
        popups: popups ? popups.popups : null,
        imagesAltText: await imagesAltText
      },
      revalidate: 600
    };

    if (dataFormatter) {
      return dataFormatter(staticProps);
    }

    return staticProps;
  } catch (err: any) {
    if (err?.name === 'NotFoundError') {
      return {
        notFound: true,
        props: {}
      };
    }

    throw err;
  }
};

export default SanityPage;
