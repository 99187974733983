import React, { useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import Lego from '@konsus/lego';
import { useAnalytics } from '@konsus/analytics';
import { useAtomMutator } from '@konsus/atoms';
import { imagesAltTextAtom } from '@konsus/image';
import { Themes } from '@superside/ui';
import { useTheme } from '@konsus/apps-common';
import Layout from '../page/Page';
import PageHelmet from '../page/PageHelmet';
import { ScrollTracker } from '../ScrollTracker';
import ThemeSwitch from '../page/ThemeSwitch';

const AnnouncementBar = dynamic(
  () => import(/* webpackChunkName: "AnnouncementBar" */ '../AnnouncementBar')
);

const BottomAnnouncementBar = dynamic(
  () =>
    import(
      /* webpackChunkName: "BottomAnnouncementBar" */ '@konsus/lego/src/components/BottomAnnouncementBar'
    ),
  { ssr: false }
);

const ExitPopup = dynamic(
  () => import(/* webpackChunkName: "Exit_Popup" */ '@konsus/lego/src/blocks/ExitPopup')
);

// TODO add types
export type SanityPageType = {
  helmetProps?: any;
  data?: any;
  hasAnnouncementBar?: boolean;
  popups?: any;
  additionalProps?: any;
  imagesAltText?: any;
  initialTimeout?: number;
};

export default function SanityPage({
  helmetProps,
  data,
  hasAnnouncementBar,
  additionalProps = {},
  imagesAltText,
  ...rest
}: SanityPageType) {
  const {
    items,
    blocks,
    isNewWebsite,
    hideHeader,
    tinyFooter,
    showFullFooter,
    metaTitle,
    metaDescription,
    nofollow,
    showFooterBanner,
    banner,
    trackPageScrolling,
    header,
    footer,
    trackingEvents,
    openGraphImage,
    isTicket,
    enableExitPopup,
    exitPopup,
    enableThemeSwitch: hasLightToDarkTransition,
    pageTheme = Themes.aurora,
    darkMode,
    specialBackground,
    predefinedBottomBanner,
    activeBottomCalloutBanner
  } = data.content;

  const { track } = useAnalytics();

  useTheme({ theme: pageTheme, darkMode, specialBackground });

  const setImagesAltTextAtom = useAtomMutator(imagesAltTextAtom);

  useEffect(() => {
    setImagesAltTextAtom(imagesAltText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesAltText]);

  useEffect(() => {
    if (trackingEvents) {
      trackingEvents?.forEach(
        (event: { name: string; properties: { name: string; value: string }[] }) => {
          const eventMeta = event.properties?.reduce(
            (acc, { name, value }) => ({
              ...acc,
              [name]: value
            }),
            {}
          );

          track(event.name, eventMeta);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navSettings = useMemo(() => {
    if (isNewWebsite) {
      return { navSettings: { header, footer } };
    }

    return {
      hideHeader,
      tinyFooter,
      showFullFooter
    };
  }, [isNewWebsite, header, footer, hideHeader, tinyFooter, showFullFooter]);

  const pageContent = (
    <>
      {predefinedBottomBanner && activeBottomCalloutBanner ? (
        <BottomAnnouncementBar data={predefinedBottomBanner} />
      ) : null}
      {hasAnnouncementBar && data.announcement && data.announcement.active ? (
        <AnnouncementBar announcement={data.announcement} />
      ) : null}
      <Layout
        showPreviewModal
        isNewWebsite={isNewWebsite}
        showFooterBanner={showFooterBanner}
        banner={banner}
        data={data}
        blocks={blocks}
        light={hasLightToDarkTransition}
        pageTheme={pageTheme}
        {...navSettings}
        {...rest}
        {...additionalProps}
      >
        <PageHelmet
          fullTitle={metaTitle}
          fullDescription={metaDescription}
          nofollow={nofollow}
          ogImageSrc={openGraphImage}
          isTicket={isTicket}
          {...helmetProps}
        />

        {enableExitPopup && exitPopup ? <ExitPopup {...exitPopup} /> : null}

        <ThemeSwitch hasLightToDarkTransition={hasLightToDarkTransition} />

        <Lego items={blocks || items} />
      </Layout>
    </>
  );

  if (!trackPageScrolling) {
    return <ScrollTracker>{pageContent}</ScrollTracker>;
  }

  return pageContent;
}
