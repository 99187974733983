import React from 'react';
import { useScrollTracking } from '@konsus/hooks';

interface ScrollTrackerProps {}

export const ScrollTracker: React.FC<ScrollTrackerProps> = (props) => {
  useScrollTracking();

  return <>{props.children}</>;
};
